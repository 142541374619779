import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserCompanyId } from '../../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../../data-access/input-is-not-null-or-undefined';
import { addDoc, collection, doc, docData, DocumentReference, Firestore } from '@angular/fire/firestore';
import { Tag } from "../get-tags/get-tags.service";

export interface CreateTagPayload {
  name: string,
}

@Injectable({
  providedIn: 'root',
})
export class CreateTagService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  create(payload: CreateTagPayload): Observable<Tag | undefined> {
    return this.store$.pipe(
      select(selectCurrentUserCompanyId),
      filter(inputIsNotNullOrUndefined),
      switchMap(companyId =>
        addDoc(collection(this.firestore, `companyTags/${ companyId }/tags`), payload),
      ),
      switchMap(documentRef =>
        docData(doc(this.firestore, documentRef.path) as DocumentReference<Tag>, { idField: 'id' })
      ),
      take(1),
    );
  }
}
