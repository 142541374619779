import { Action, createReducer, on } from '@ngrx/store';
import { CreateTagState } from './create-tag.types';
import { createTagAction, createTagSuccessAction, createTagFailureAction } from './create-tag.actions';

const initialState = {
  updating: false,
  updated: false,
  data: undefined,
  error: null,
};

const createTagReducer = createReducer<CreateTagState>(
  initialState,
  on(
    createTagAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
    })
  ),
  on(
    createTagSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    createTagFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: CreateTagState, action: Action) => createTagReducer(state, action);
